import $ from 'jquery';
import { gsap } from 'gsap';

import { $Elements, Animations, Classes, Elements, Events } from '../global/constants';

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Menu ----------

	$Elements.HeaderWrapper.each(function() {

		Animations.global.push(() => {

			gsap.timeline({
				scrollTrigger: {
					start: 1,
					end: 'max',
					onUpdate: (self) => {
						(self.direction === -1) ? this.classList.remove(Classes.shrink) : this.classList.add(Classes.shrink);
						(self.progress > 0) ? this.classList.add(Classes.shrinkable) : this.classList.remove(Classes.shrinkable);
					}
				}
			});
		});
	});

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 90%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed):not(.transition-clone)').each(function() {

		Animations.mobile.max.push(() => this.classList.remove(Classes.moving));

		Animations.mobile.min.push(() => {

			this.classList.add(Classes.moving);

			const image = this.querySelector(':scope .section-image');
			const speed = parseInt(this.dataset.speed, 10);

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
					},
				});

				const offset = () => image.offsetHeight - ((image.offsetHeight - this.offsetHeight) / 2);

				timeline.fromTo(
					image,
					{ y: () => offset() * (speed / 100), ease: 'none' },
					{ y: () => offset() * (-speed / 100), ease: 'none' }
				);
			}
		});
	});

	// ---------- Home Sections ----------

	$('.home-introduction').each(function() {

		Animations.global.push(() => {

			const background = this.querySelector(':scope .section-content .background');
			const logo = this.querySelector(':scope .section-content .logo');
			const scroll = this.querySelector(':scope .section-content .scroll');
			const image = this.querySelector(':scope .image-column .column-image');

			const longTimeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top top',
					end: 'bottom bottom',
					scrub: true,
					invalidateOnRefresh: true,
				},
			});

			const shortTimeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top 1px',
					end: 'top -33%',
					scrub: true,
					invalidateOnRefresh: true,
					toggleClass: {
						className: Classes.menuLight,
						targets: Elements.HeaderWrapper
					}
				},
			});

			if(background) {

				longTimeline.to(background, {
					scale: 25,
					ease: 'none',
				}, 0);
			}

			if(image) {

				longTimeline.to(image, {
					scale: 1.2,
					ease: 'none',
				}, 0);
			}

			if(logo) {

				shortTimeline.to(logo, {
					opacity: 0,
					scale: .75,
					yPercent: -50,
					ease: 'none',
				}, 0);
			}

			if(scroll) {

				shortTimeline.to(scroll, {
					opacity: 0,
					yPercent: 10,
					ease: 'none',
				}, 0);
			}
		});
	});

	$('.home-collection').each(function(index) {

		Animations.global.push(() => {

			const count = $('.home-collection').length - 1;
			const image = this.querySelector(':scope .home-collection-image .column-image');
			const illustration = this.querySelector(':scope .home-collection-illustration');
			const introduction = this.querySelector(':scope .home-collection-introduction');
			const packshot = this.querySelector(':scope .home-collection-packshot');

			const active = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top bottom',
					end: 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
					toggleClass: Classes.active
				},
			});

			const pin = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top top',
					end: (index === count) ? 'bottom bottom' : 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
					pin: true,
					pinSpacing: false,
				},
			});

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top top',
					end: 'bottom top',
					scrub: true,
					invalidateOnRefresh: true,
				},
			});

			if(image) {

				timeline.to(image, {
					scale: 1.25,
					duration: 1,
					ease: 'none',
				}, 0);
			}

			if(illustration) {

				if(index < count) {

					timeline.fromTo(illustration, {
						yPercent: 100,
					}, {
						yPercent: -100,
						duration: 1,
						ease: 'none',
					}, 0);

					timeline.fromTo(illustration, {
						opacity: 0,
					}, {
						opacity: .4,
						duration: .5,
					}, 0);

					timeline.fromTo(illustration, {
						opacity: .4,
					}, {
						opacity: 0,
						duration: .5,
					}, .5);
				}
				else {

					pin.fromTo(illustration, {
						yPercent: 100,
						opacity: 0,
					}, {
						yPercent: 0,
						opacity: .4,
						duration: 1,
						ease: 'none',
					}, 0);
				}
			}

			if(introduction) {

				if(index) {

					timeline.fromTo(introduction, {
						opacity: 0,
					}, {
						opacity: 1,
						duration: (index < count) ? .5 : 1,
						ease: 'none',
					}, 0);
				}

				if(index < count) {

					timeline.to(introduction, {
						opacity: 0,
					}, .5);
				}
			}

			if(packshot) {

				if(index < count) {

					timeline.fromTo(packshot, {
						y: '25vh',
					}, {
						y: '-25vh',
						duration: 1,
						ease: 'none',
					}, 0);

					timeline.to(packshot, {
						opacity: 0,
						duration: .5,
					}, .5);
				}
				else {

					pin.fromTo(packshot, {
						y: '25vh',
					}, {
						y: '0vh',
						duration: 1,
						ease: 'none',
					}, 0);
				}
			}
		});
	});
};

$Elements.document.on(Events.page.enter, () => initScrollAnimations());
