import $ from 'jquery';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

import { $Elements, Durations, Events, } from './constants';

$Elements.document.on(Events.page.enter, () => {

	$('.gallery-carousel-section .section-slideshow, .gallery-carousel-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: true,
				slidesPerView: 'auto',
				centerInsufficientSlides: true,
				modules: [Navigation],
				navigation: {
					nextEl: this.querySelector(".swiper-button-next"),
					prevEl: this.querySelector(".swiper-button-prev"),
				},
			});
		}
	});

	// ---------- Wine slider section ----------

	$('.wine-slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: Durations.animations.ms,
				loop: false,
				observer: true,
				slidesPerView: 'auto',
				centerInsufficientSlides: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					type: 'progressbar',
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});
});
