import { $Elements, Classes, Durations, Elements, Events, tcVar } from '../global/constants';
import { wait } from '../global/helpers';

export const initPageTransition = (links, events = true) => {

	const overlay = (tcVar('has-loading-overlay') === 'true');

	// ---------- Handle In ----------

	if(events) {
		$Elements.document.ready(() => $Elements.document.trigger(Events.page.enter));
	}

	$Elements.window.on('load', transitionIn);

	$Elements.window.on('pageshow', async e => {
		if(e.originalEvent.persisted) {
			window.location.reload();
			await transitionIn();
		}
	});

	// ---------- Handle Out ----------

	$Elements.window.on('unload', () => null);

	$Elements.document.on('click', links, async e => {

		e.preventDefault();

		await transitionOut();

		if(e.which === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey) {
			window.open(e.currentTarget.href, '_blank');
		}
		else {
			window.location.href = e.currentTarget.href;
		}
	});

	// ---------- Transitions ----------

	async function transitionIn() {

		if(events) {
			$Elements.document.trigger(Events.page.loaded);
		}

		Elements.LoadingBar.classList.remove(Classes.loading);
		Elements.MainWrapper.classList.remove(Classes.loading, Classes.initialLoad);
		Elements.LoadingOverlay.classList.remove(Classes.loading, Classes.initialLoad);
	}

	async function transitionOut() {

		if(events) {
			$Elements.document.trigger(Events.page.leave);
		}

		Elements.LoadingBar.classList.add(Classes.loading);

		if(overlay) {
			Elements.LoadingOverlay.classList.add(Classes.loading);
			Elements.MainWrapper.classList.add(Classes.loading);

			await wait(Durations.page.ms);
		}
	}
};
